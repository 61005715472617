import EmployeesRepositoryInterface from '../repositories/interfaces/EmployeesRepositoryInterface'
import RequestsRepositoryInterface from '../repositories/interfaces/RequestsRepositoryInterface'
import WorkshiftsRepositoryInterface from '../repositories/interfaces/WorkshiftsRepositoryInterface'
import SpecialtiesRepositoryInterface from '../repositories/interfaces/SpecialtiesRepositoryInterface'

const bind = (repositoryName, Interface) => ({
	...Object.keys(Interface).reduce((prev, method) => {
		const resolveableMethod = async (...args) => {
			const repository = await import(
				'../repositories/' + repositoryName + '.js'
			)
			return repository.default[method](...args)
		}
		return { ...prev, [method]: resolveableMethod }
	}, {}),
})

export default {
	employeesRepository: bind(
		'EmployeesRepository',
		EmployeesRepositoryInterface,
	),
	requestsRepository: bind('RequestsRepository', RequestsRepositoryInterface),
	workshiftsRepository: bind('WorkshiftsRepository', WorkshiftsRepositoryInterface),
	specialtiesRepository: bind('SpecialtiesRepository', SpecialtiesRepositoryInterface),
}
